<template>
  <!-- 配件选择 -->
  <div class="partsSelect flex flex-col">
    <div class="flex-1 flex space-x-4 h-4/5">
      <div class="flex-1  flex flex-col overflow-auto part_border">
        <div
          class="flex items-center justify-between px-8 py-4 cursor-pointer part_item"
          :class="[subIndex == index ? 'active' : '']"
          v-for="(item, index) in list"
          :key="index"
          @click="subClick(item, index)"
        >
          <span>{{ item.name }}</span>
          <i class="iconfont icon-line_right text-sm"></i>
        </div>
      </div>
      <div class="flex-1  flex flex-col overflow-auto part_border">
        <div
          class="flex items-center justify-between px-8 py-4 cursor-pointer part_item"
          :class="[c_index == c_i ? 'active' : '']"
          v-for="(item1, c_i) in list1"
          :key="c_i + 'c'"
          @click="cClick(item1, c_i)"
        >
          <span>{{ item1.name }}</span>
          <i class="iconfont icon-line_right text-sm"></i>
        </div>
      </div>
      <div class="flex-1  flex flex-col overflow-auto part_border">
        <div
          class="flex items-center justify-between px-8 py-4 cursor-pointer part_item"
          :class="[item2.checked ? 'active' : '']"
          v-for="(item2, r_i) in checkedData"
          :key="r_i + 'r'"
          @click="selectClick(item2)"
        >
          <span>{{ item2.name }}</span>
          <i
            class="iconfont icon-fill_complete text-sm text-warning"
            v-if="item2.checked"
          ></i>
        </div>
      </div>
    </div>
    <div class="text-right mt-8 mr-8">
      <el-button plain size="small" @click="cancelFn">取消</el-button>
      <el-button type="primary" plain size="small" @click="sureFn"
        >确定({{ checkedList.length }})</el-button
      >
    </div>
  </div>
</template>

<script>
import { getDataClassGroup } from "@/api/basic";

export default {
  name: "partsSelect",
  props: {
    carObj: {
      type: Object,
    },
  },
  data() {
    return {
      list: [],
      list1: [],
      list2: [],
      subIndex: 0,
      c_index: 0,
      r_index: 0,
      checkedList: [],
    };
  },
  watch: {
    carObj: {
      handler(val) {
        console.log("val", val);
        if (val) {
          this.init();
        }
      },
      deep: true,
    },
  },
  computed: {
    checkedData() {
      let arr = [];
      this.list2.forEach((q) => {
        if (this.checkedList.length) {
          this.checkedList.forEach((p) => {
            if (p == q.name) {
              q.checked = true;
            }
          });
        } else {
          q.checked = false;
        }
        arr.push(q);
      });
      return arr;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      let params = {
        ...this.carObj,
        type: 0, //# 数据类型，0:整车基本分类;1:整车配件分类
      };
      getDataClassGroup(params).then((res) => {
        this.list = res || [];
        // console.log("list", this.list);
        this.subClick(res[0], 0);
      });
    },
    subClick(item, index) {
      this.subIndex = index;
      this.c_index = 0;
      this.list1 = item.rows;
      let arr = this.list1[0].rows;
      arr.forEach((item) => {
        item.checked = false;
      });
      this.list2 = arr;
    },
    cClick(item, index) {
      this.c_index = index;
      let arr = item.rows;
      arr.forEach((item) => {
        item.checked = false;
      });
      this.list2 = arr;
    },
    selectClick(item) {
      if (!item.checked) {
        this.checkedList.push(item.name);
      } else {
        let index = this.checkedList.findIndex((p) => p == item.name);
        this.checkedList.splice(index, 1);
      }
      // console.log("checklist", this.checkedList);
      item.checked = !item.checked;
    },
    cancelFn() {
      this.checkedList = [];
      this.$forceUpdate();
      this.$emit("cancel");
    },
    sureFn() {
      this.$emit("sure", this.checkedList);
    },
  },
};
</script>

<style lang="less">
.partsSelect {
  height: 400px;
  .part_item:hover {
    background: #ffeeeb;
  }
  .active {
    background: #ffeeeb;
  }
  .part_border {
    border: 1px solid #ededed;
  }
}
</style>
