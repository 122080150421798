<template>
  <div class="h-full flex flex-col">
    <el-table
      :data="tableData"
      border
      stripe
      size="small"
      class="table-base"
      height="250"
      style="width: 100%;"
    >
      <template v-for="(column, index) in columns">
        <el-table-column
          :key="index"
          :type="column.type"
          :align="column.align"
          :label="column.label"
          :prop="column.prop"
          :min-width="column.width"
          :fixed="column.fixed"
          show-overflow-tooltip
        >
          <template slot="header" slot-scope="scope">
            <span class="text-warning" v-if="renderHeader(column.prop)">
              *
            </span>
            <span>{{ scope.column.label }}</span>
            <span v-if="column.prop == 'originCode'">
              <el-popover placement="top" width="300" trigger="hover">
                <div>
                  <div class="text-sm font-semibold">说明</div>
                  <div class="mt-8">
                    按品类选择或输入名称时是选择的系统匹配到的名称的情况下才有可能带出原厂零件号
                  </div>
                </div>
                <i
                  class="el-icon-question text-warning text-sm ml-4 cursor-pointer"
                  slot="reference"
                ></i>
              </el-popover>
            </span>
          </template>
          <template slot-scope="scope">
            <span v-if="column.prop == 'index'">{{ scope.$index + 1 }}</span>
            <span v-else-if="column.prop == 'partName'">
              <!-- <el-input
                v-model="scope.row[column.prop]"
                size="mini"
                placeholder="请输入"
              ></el-input> -->
              <el-autocomplete
                style="width:100%"
                size="mini"
                v-model="scope.row[column.prop]"
                :fetch-suggestions="querySearch"
                placeholder="请输入配件编码/名称"
                :trigger-on-focus="false"
              ></el-autocomplete>
            </span>
            <span v-else-if="column.prop == 'qty'">
              <!-- <el-input
                v-model="scope.row[column.prop]"
                size="mini"
                placeholder="请输入"
              ></el-input> -->
              <el-input-number
                size="mini"
                :precision="0"
                v-model="scope.row[column.prop]"
                controls-position="right"
                style="width:100%"
                :min="1"
                :max="999999999"
              ></el-input-number>
            </span>
            <span v-else-if="column.prop == 'controls'">
              <el-button
                type="text"
                class="text-warning"
                size="mini"
                @click="delBtnFn(scope)"
              >
                <i class="iconfont icon-line_delete text-warning text-sm"></i>
                删除
              </el-button>
            </span>
            <span v-else>{{ scope.row[column.prop] }}</span>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <div class="mt-8">
      <el-button
        class="text-warning border-warning"
        size="small"
        plain
        round
        @click="addFn"
        >+ 追加</el-button
      >
    </div>
  </div>
</template>

<script>
import { getNamesApi } from "@/api/basic";

//查看报价
const defaultColumns = [
  {
    label: "序号",
    prop: "index",
    width: "60",
    align: "center",
  },
  {
    prop: "partName",
    label: "配件名称",
    width: "180",
  },
  {
    prop: "qty",
    label: "数量",
    width: "180",
  },
  {
    prop: "originCode",
    label: "原厂零件号",
    width: "180",
  },

  {
    label: "操作",
    prop: "controls",
    width: "60",
    align: "center",
  },
];
const defaultRowObj = {
  // quality: [], //品质
  // price: "", //单价
  partName: "", //配件名称
  partCode: "", //配件编码
  qty: "1", // # 数量
  originCode: "", //原厂零件号

  // productionPlace: "", // # 产地
  // brand: "", //# 配件品牌
  // stock: 1, //库存
  // zhibao: "", //质保(天)
  // gonghuozhouqi: "", //供货周期 (天)
  // remark: "", //备注
};
export default {
  name: "partsTableList",
  props: {
    partsList: {
      type: Array,
      default: () => {
        return [{ ...defaultRowObj }];
      },
    },
  },
  data() {
    return {
      tableData: [{ ...defaultRowObj }],
      columns: defaultColumns,
    };
  },
  watch: {
    partsList: {
      handler(val) {
        if (val) {
          this.tableData = val;
        }
      },
      deep: true,
    },
    tableData: {
      handler(val) {
        if (val) {
          this.$emit("change", val);
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (this.partsList.length) {
      this.tableData = [...this.partsList];
    }
  },
  methods: {
    querySearch(value, cb) {
      let params = {
        value,
      };
      getNamesApi(params).then((res) => {
        let arr = [];
        if (res && res.length) {
          res.forEach((p) => {
            arr.push({ value: p });
          });
        }
        // 调用 callback 返回建议列表的数据
        cb(arr);
      });
    },
    renderHeader(prop) {
      let flag = false;
      if (prop == "partName") {
        flag = true;
      }
      return flag;
    },
    delBtnFn({ $index }) {
      this.tableData.splice($index, 1);
    },
    addFn() {
      // if (this.tableData.length) {
      //   let copyRows = JSON.parse(JSON.stringify(this.tableData));
      //   let obj = copyRows[copyRows.length - 1];
      //   //如果列表中有数据，默认添加最后一条，否则添加模版数据
      //   this.tableData.push(obj);
      // } else {
      this.tableData.push({ ...defaultRowObj });
      // }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-button--default:hover {
  border-color: #ff6e4c;
  color: #ff6e4c;
  background-color: #ff6e4c24;
}
/deep/.el-button--text:hover {
  color: #ff6e4c;
}
</style>
