<template>
  <!-- 发布询价 -->
  <div class="bg-white flex flex-col postEnquiry">
    <div class="flex-1 px-16  overflow-auto">
      <!-- 车辆信息 -->
      <div class="my-24">
        <div class="flex justify-between">
          <div class="flex items-center">
            <div class="w-4 h-24 bg-warning mr-8"></div>
            <div class="text-lg font-semibold">车辆信息</div>
          </div>
          <el-button
            class="text-warning border-warning"
            size="small"
            round
            @click="toEnquiryFn"
            >我的询价历史</el-button
          >
        </div>
        <div class="flex items-center mt-16">
          <div class="flex items-center mr-16">
            <div class="mr-8">VIN码</div>
            <el-input
              placeholder="请输入17位VIN码"
              class="flex-1"
              style="max-width:200px;min-width: 180px;"
              size="small"
              maxlength="17"
              v-model="vinUpper"
              @blur="matchFn"
            >
              <span
                class="el-input__icon flex items-center justify-center"
                slot="suffix"
              >
                <label class="vin-code" ref="vinFile">
                  <input
                    id="vinFile"
                    v-show="false"
                    ref="picFile"
                    type="file"
                    accept="image/*"
                    @change="readFile"
                  />
                </label>
              </span>

              <!-- <i slot="suffix" class="el-icon-camera-solid"></i> -->
            </el-input>
          </div>
          <div class="flex items-center" style="width:742px;">
            <div class="mr-8">
              <span class="text-warning">*</span>
              车型
            </div>
            <el-select
              class="flex-1 mr-8"
              size="small"
              placeholder="请选择"
              filterable
              v-model="formData.factoryBrand"
              :disabled="formData.vin.length == 17"
              @change="(value) => selCarChange(value, 'factoryBrand')"
            >
              <el-option
                v-for="item in factoryBrandList"
                :key="item.name"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
            <el-select
              class="flex-1 mr-8"
              size="small"
              placeholder="请选择"
              filterable
              v-model="formData.series"
              :disabled="formData.vin.length == 17"
              @change="(value) => selCarChange(value, 'series')"
            >
              <el-option
                v-for="item in seriesList"
                :key="item.name"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
            <el-select
              class="flex-1 mr-8"
              size="small"
              placeholder="请选择"
              filterable
              v-model="formData.year"
              :disabled="formData.vin.length == 17"
              @change="(value) => selCarChange(value, 'year')"
            >
              <el-option
                v-for="item in yearList"
                :key="item.name"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
            <el-select
              class="flex-1"
              size="small"
              placeholder="请选择"
              filterable
              v-model="combine"
              :disabled="formData.vin.length == 17"
              @change="(value) => selCarChange(value, 'combine')"
            >
              <el-option
                v-for="item in combineList"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <!-- 添加配件 -->
      <div class="my-24">
        <div class="flex items-center">
          <div class="w-4 h-24 bg-warning mr-8"></div>
          <div class="text-lg font-semibold">添加配件</div>
        </div>
        <div class="relative">
          <el-tabs v-model="activeName">
            <el-tab-pane label="配件列表" name="parts">
              <div style="height:350px;">
                <PartsTableList
                  :partsList="formData.partDetails"
                  @change="tableChange"
                />
              </div>
            </el-tab-pane>
            <el-tab-pane label="Excel复制配件" name="excel">
              <div style="height:350px;">
                <PartsTableList
                  :partsList="formData.partDetails"
                  @change="tableChange"
                />
              </div>
            </el-tab-pane>
          </el-tabs>
          <div class="absolute ">
            <div v-if="activeName == 'parts'">
              <span
                class="cursor-pointer mr-24 btn_hover"
                @click="clearPartsFn"
              >
                <i class="iconfont icon-line_delete text-12"></i>
                清空配件
              </span>
              <el-popover
                placement="bottom"
                width="800"
                v-model="partsInfoVisible"
              >
                <PartsSelect
                  :carObj="carObj"
                  @cancel="cancelFn"
                  @sure="sureFn"
                />
                <span class="cursor-pointer btn_hover" slot="reference">
                  按品类选配件
                  <i
                    class="iconfont  text-12"
                    :class="[
                      partsInfoVisible ? 'icon-fill_up' : 'icon-fill_down',
                    ]"
                  ></i>
                </span>
              </el-popover>
              <!-- <span
                class="cursor-pointer btn_hover"
                slot="reference"
                @click="selectPartsFn"
              >
                按品类选配件
                <i class="iconfont icon-fill_down text-12"></i>
              </span> -->
            </div>
            <div v-if="activeName == 'excel'">
              <span
                class="cursor-pointer mr-24 btn_hover"
                @click="clearPartsFn"
              >
                <i class="iconfont icon-line_delete text-12"></i>
                清空配件
              </span>
              <label class="cursor-pointer mr-24 btn_hover" ref="uploadExcel"
                >导入
                <input
                  type="file"
                  name="file"
                  id="uploadExcel"
                  v-show="false"
                  ref="excelFile"
                  @change="Upload"
                />
              </label>
            </div>
          </div>
        </div>
      </div>
      <!-- 其他信息 -->
      <div class="my-24">
        <div class="flex items-center">
          <div class="w-4 h-24 bg-warning mr-8"></div>
          <div class="text-lg font-semibold">其他信息</div>
        </div>

        <div class="flex">
          <!-- 供货要求 -->
          <div class="flex-1 item_else_box">
            <div class="flex">
              <span class="item_title">供货要求</span>
            </div>
            <div class="my-8">
              <div class="py-8 text-sm font-semibold">发票类型</div>
              <el-radio-group v-model="invoiceType">
                <el-radio
                  v-for="(item, index) in invoiceTypeList"
                  :key="index"
                  :label="item.name"
                  >{{ item.name }}</el-radio
                >
              </el-radio-group>
            </div>
            <div class="my-8">
              <div class="py-8 text-sm font-semibold">品质</div>
              <div class="flex flex-wrap items-center ">
                <el-radio-group
                  v-model="formData.qualityNone"
                  class="flex items-center pin_radio"
                  @change="selectQualityFn"
                >
                  <el-radio :label="true">无限制</el-radio>
                </el-radio-group>
                <span
                  :class="['item_quality', quality.checked ? 'active' : '']"
                  v-for="(quality, q_i) in qulityList"
                  :key="q_i + 'q'"
                  @click="selQuility(quality)"
                  >{{ quality.text }}</span
                >
              </div>
            </div>
            <div class="my-8">
              <div class="py-8 text-sm font-semibold">地区</div>
              <el-radio-group v-model="formData.pushArea">
                <el-radio
                  v-for="(item, index) in areaData"
                  :key="index"
                  :label="item.code"
                  >{{ item.name }}</el-radio
                >
              </el-radio-group>
            </div>
          </div>
          <!-- 收货信息 -->
          <div class="flex-1 item_else_box">
            <div class="flex justify-between items-center">
              <span class="item_title">收货信息</span>
              <el-button
                class="text-warning border-warning"
                size="small"
                round
                @click="toAddressFn"
              >
                + 新增地址
              </el-button>
            </div>
            <template v-for="(item, a_i) in addresslist">
              <div class="flex items-center my-16 address_item" :key="a_i">
                <i
                  class="iconfont icon-fill_complete text-warning cursor-pointer"
                  v-if="item.isDefault"
                ></i>
                <i
                  class="iconfont icon-radio_nor text-gray-900 cursor-pointer"
                  v-else
                  @click="setDefaultAddress(item.id)"
                ></i>
                <div class="p-16 bg-gray-200 flex-1 rounded m-l-12 ">
                  <div>
                    <span class="text-sm font-semibold mr-8">
                      {{ item.receiver }}
                    </span>
                    <span class="text-sm">{{ item.phone }}</span>
                  </div>
                  <div class="text-xs mt-4">
                    <span>
                      {{ item.provinceName }}/{{ item.cityName }}/{{
                        item.areaName
                      }}
                    </span>
                    <span>{{ item.address }}</span>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <!-- 备注信息 -->
          <div class="flex-1 item_else_box">
            <div class="flex">
              <span class="item_title">备注信息</span>
            </div>
            <div class="mt-16">
              <span class="text-sm font-semibold">图片</span>
              <span class="text-xs text-gray-900">（注意：图片最多10张）</span>
            </div>
            <div class="mt-8 upload_img">
              <UploadFileImg
                :maxSize="1"
                listType="picture-card"
                :limit="10"
                :fileList="pictures"
                :isMultiple="false"
                :showFileList="true"
                :hideFlag="true"
                @getFileList="(fileList) => getFileList(fileList, 'pictures')"
              />
            </div>
            <div class="mt-16">
              <span class="text-sm font-semibold">备注</span>
            </div>
            <div class="mt-8">
              <el-input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 5 }"
                placeholder="请输入内容"
                v-model="formData.remarks"
              >
              </el-input>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-center items-center box-shadow10">
      <div class="my-8">
        <el-button
          class="text-warning border-warning mr-16"
          size="small"
          round
          @click="save"
        >
          保存
        </el-button>
        <el-button type="danger" size="small" round @click="publish"
          >发布询价</el-button
        >
      </div>
    </div>
    <el-dialog
      title="新增/修改地址"
      :visible.sync="dialogVisible"
      :width="'35%'"
    >
      <EditAddressInfo
        v-if="dialogVisible"
        @cancle="dialogVisible = false"
        @afterOperate="getAddress"
      />
    </el-dialog>
  </div>
</template>

<script>
import EditAddressInfo from "@/views/userCenter/address/components/EditAddressInfo.vue";
import UploadFileImg from "@/components/Upload/UploadFileImg.vue";
import PartsTableList from "../components/partsTableList.vue";
import PartsSelect from "../components/partsSelect.vue";

import { read, utils } from "xlsx";
import Compressor from "compressorjs";
import * as imageConversion from "image-conversion";

import { getVehModel, getDict, getVIN, getOrcVin } from "@/api/basic";
import { addressesListApi, setDefaultAddressApi } from "@/api/address";
import { enquirySave, enquiryPublish, enquiryDetail } from "@/api/enquiry";

export default {
  name: "postEnquiry",
  // props: {
  //   enquiryCode: {
  //     type: String,
  //   },
  // },
  components: {
    UploadFileImg,
    EditAddressInfo,
    PartsTableList,
    PartsSelect,
  },
  data() {
    return {
      formData: {
        vehBrand: "", //# 汽车品牌
        factoryBrand: "", // # 汽车厂牌
        series: "", //# 车系
        year: "", //# 年款
        displacement: "", // # 排量
        gearbox: "", //# 变速器
        vin: "", //# vin码

        invoiceType: "", //发票
        quality: [], //品质
        qualityNone: true,
        pushArea: "none", //所在地区
        // areaId: 1254,
        partDetails: [
          // {
          //   partName: "大灯", //配件名称
          //   partCode: "123", //配件编码
          //   qty: "1", // # 数量
          //   originCode: "123313", //原厂零件号
          // },
        ],
        pictures: [],
        remarks: "",
      },
      invoiceType: "无限制", //发票
      combine: "",

      factoryBrandList: [],
      seriesList: [],
      yearList: [],
      combineList: [],

      qulityList: [],
      invoiceTypeList: [
        // {
        //   code: "none",
        //   name: "无限制",
        // },
        // {
        //   code: "receipt",
        //   name: "收据",
        // },
        // {
        //   code: "regularInvoice",
        //   name: "普通发票",
        // },
        // {
        //   code: "appreciationInvoice",
        //   name: "增值发票",
        // },
      ],
      areaData: [
        {
          code: "none",
          name: "无限制",
        },
        {
          code: "thisCity",
          name: "本市",
        },
        {
          code: "thisProvince",
          name: "本省",
        },
      ],
      activeName: "parts",

      addresslist: [],
      pictures: [],
      dialogVisible: false,
      partsInfoVisible: false,
      carObj: {},
      //excel
      Uploadshow: false,
      excelValue: "",
      // sheet: ["* 配件编码", "* 数量", "品质"],
      // sheetClomns: [
      //   {
      //     key: "* 配件编码",
      //     value: "partCode",
      //   },
      //   {
      //     key: "* 数量",
      //     value: "qty",
      //   },
      //   {
      //     key: "品质",
      //     value: "quality",
      //   },
      // ],
    };
  },
  watch: {
    pictures: {
      handler: function(newVal) {
        this.formData.pictures =
          newVal && newVal.length > 0
            ? newVal.map((item) => item.origin || item)
            : [];
      },
      deep: true,
    },
  },
  computed: {
    vinUpper: {
      get: function() {
        return this.formData.vin;
      },
      set: function(val) {
        this.formData.vin = val.toUpperCase();
      },
    },
    searchTypes() {
      let arr = [
        {
          name: "汽车厂牌",
          type: "factoryBrand",
        },
        {
          name: "车系",
          type: "series",
        },
        {
          name: "年款",
          type: "year",
        },
        {
          name: "排量和变速器",
          type: "combine",
        },
      ];
      return arr;
    },
    invoiceTypeNone() {
      return this.invoiceType == "无限制" ? true : false;
    },
  },
  created() {
    this.getAddress();
    this.getCarBrand("factoryBrand");
    this.getQulityList();
    this.getInsureCertType();
  },
  mounted() {
    if (!this.$store.state.profile.id) {
      this.$router.push("/login");
      return;
    }
    if (this.$route.query.enquiryCode) {
      this.load(this.$route.query.enquiryCode);
    }
    if (this.$route.query.keyword) {
      let obj = {
        partName: this.$route.query.keyword,
        qty: 1,
      };
      this.formData.partDetails.push(obj);
    }
  },
  methods: {
    readFile() {
      const _this = this;
      const inputFile = this.$refs.picFile.files[0];
      const loading = this.$loading({
        lock: true,
        text: "识别中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      // console.log("压缩前", inputFile.size);
      if (inputFile.size > 6000000) {
        new Compressor(inputFile, {
          quality: 0.1,
          success(result) {
            // console.log("压缩1result", result.size);

            if (result.size > 1000000) {
              imageConversion.compressAccurately(result, 300).then((file) => {
                // console.log("压缩2file", file.size);

                _this.file2Xce(file).then((res) => {
                  _this.vinParse(_this, loading, res, file.type);
                });
              });
              return;
            }
            _this.file2Xce(result).then((res) => {
              _this.vinParse(_this, loading, res, result.type);
            });
          },
        });
      } else if (inputFile.size > 600000) {
        imageConversion.compressAccurately(inputFile, 300).then((file) => {
          // console.log("压缩后file：", file.size);
          _this.file2Xce(file).then((res) => {
            _this.vinParse(_this, loading, res, file.type);
          });
        });
      } else {
        // console.log("没压缩", inputFile.size);
        _this.file2Xce(inputFile).then((res) => {
          _this.vinParse(_this, loading, res, inputFile.type);
        });
      }
    },
    file2Xce(file) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = function(e) {
          const data = e.target.result;
          resolve(data);
        };
        reader.readAsDataURL(file);
      });
    },
    vinParse(_this, loading, fileData, fileType) {
      const imgCode = fileData.replace(`data:${fileType};base64,`, "");
      getOrcVin({ fileData: imgCode })
        .then((res) => {
          loading.close();
          _this.formData.vin = res;
          _this.$refs.picFile.value = "";
          _this.matchFn();
        })
        .catch((err) => {
          loading.close();
          _this.$refs.picFile.value = "";
          if (err && err.message) {
            _this.$message.error(err.message);
          }
        });
    },
    cancelFn() {
      this.partsInfoVisible = false;
    },
    sureFn(data) {
      console.log(data, 111);
      let arr = [];
      data.forEach((p) => {
        arr.push({
          partName: p,
          qty: 1,
        });
      });
      this.formData.partDetails = [...arr];
      this.partsInfoVisible = false;
    },
    clearPartsFn() {
      this.formData.partDetails = [];
    },
    selectPartsFn() {
      this.partsInfoVisible = true;
    },
    toEnquiryFn() {
      this.$router.push("/home/myEnquiry");
    },
    tableChange(data) {
      // console.log("tableChange", data);
      this.formData.partDetails = data;
      // console.log("partDetails", this.formData.partDetails);
    },
    save() {
      this.type = "save";
      this.subHandle();
    },
    //发布
    publish() {
      this.type = "publish";
      this.subHandle();
    },
    subHandle() {
      let params = {};
      if (!this.addresslist.length) {
        this.$message.error("请添加收货地址！");
        return;
      }
      if (this.formData.factoryBrand == "") {
        this.$message.error("请选择车型！");
        return;
      }
      let addressObj = this.addresslist[0];
      let {
        address,
        areaId,
        // areaName,
        // cityName,
        // provinceName,
        phone,
        receiver,
      } = addressObj;
      if (this.formData.partDetails && this.formData.partDetails.length > 0) {
        let flag = false;
        for (let item of this.formData.partDetails) {
          item.qty = parseInt(item.qty);
          if (item.partName == "") {
            flag = true;
          }
        }
        if (flag) {
          this.$message.error("配件名称不能为空！");
          return;
        }
      }
      params = {
        // enquiryCode: this.formData.enquiryCode, // # 询价单号
        enquiryType: 0, //# 询价类型，0:车型询价，1:范围询价，2:通信快速询价
        userId: this.$store.state.profile.id, // # 发布者用户ID（users.id）
        companyId: this.$store.state.profile.companyId, // # 发布者公司ID (companies.id）
        // status: 0, // # 状态,1:待发布;2:待报价;3:已报价;
        // subStatus: "", // # 子状态,enquirying:询价中;stoped:停止询价;

        vehBrand: this.formData.vehBrand, // 汽车品牌,车型数据
        factoryBrand: this.formData.factoryBrand, // 汽车厂牌,车型数据
        series: this.formData.series, // 车系,车型数据
        year: this.formData.year.toString(), // 年款,车型数据
        displacement: this.formData.displacement, // 排量,车型数据
        gearbox: this.formData.gearbox, // 变速器,车型数据
        vin: this.formData.vin, // vin码
        brandsScope: [], //# 品牌范围
        pictures: this.formData.pictures, // # 上传的图片

        remarks: this.formData.remarks, // 备注说明
        invoiceType: this.invoiceType == "无限制" ? "" : this.invoiceType, //	 # 发票类型,存中文
        quality: this.formData.quality, //# 品质说明,存中文
        pushArea: this.formData.pushArea, // # 推送地区，none:无限制；thisCity:本市；thisProvince:本省
        receiveAreaId: areaId, //# 收货地址所在区域县级ID
        receiveName: receiver, // # 收货地址中联系人
        receivePhone: phone, //# 收货地址中联系电话
        receiveAddress: address, //# 收货地址中详细地址
        invoiceTypeNone: this.invoiceTypeNone, // # 发票类型无限制,true:是
        qualityNone: this.formData.qualityNone, // # 品质无限制,true:是

        partDetails: this.formData.partDetails,
      };
      // console.log("params", params);
      let successMsg = "";
      if (this.type == "save") {
        successMsg = "保存成功！";
        enquirySave(params)
          .then(() => {
            this.$message.success(successMsg);
            this.$router.push("/home/myEnquiry");
            // this.formData.enquiryCode = data.enquiryCode;
          })
          .catch((err) => {
            if (err && err.message) {
              this.$message.error(err.message);
            }
          });
      } else if (this.type === "publish") {
        successMsg = "发布成功！";
        enquiryPublish(params)
          .then(() => {
            this.$message.success(successMsg);
            this.$router.push("/home/myEnquiry");
          })
          .catch((err) => {
            if (err && err.message) {
              this.$message.error(err.message);
            }
          });
      }
    },
    //vin码匹配车型
    matchFn() {
      if (this.formData.vin) {
        // vin="LSVFV6182F2136613"//测试数据
        getVIN({
          vin: this.formData.vin,
        })
          .then((data) => {
            if (data && data.length > 0) {
              this.mactchBrand(data[0]);
            }
          })
          .catch((data) => {
            if (data && data.message) {
              this.$message.error(data.message);
            }
          });
      }
    },
    //给车型赋值
    mactchBrand(data) {
      this.formData.factoryBrand = data.maker || data.factoryBrand;
      if (
        !this.factoryBrandList.some(
          (item) => item == this.formData.factoryBrand
        )
      ) {
        this.factoryBrandList.push(this.formData.factoryBrand);
      }
      this.formData.series = data.vehicleChn || data.series;
      if (!this.seriesList.some((item) => item == this.formData.series)) {
        this.seriesList.push(this.formData.series);
      }
      this.formData.year = data.year;
      if (!this.yearList.some((item) => item == this.formData.year)) {
        this.yearList.push(this.formData.year);
      }
      this.formData.displacement = data.displacement;
      this.formData.gearbox = data.transmission || data.gearbox || "";
      this.combine = this.formData.displacement + " " + this.formData.gearbox;
      if (
        !this.combineList.some(
          (item) =>
            item == this.formData.displacement + " " + this.formData.gearbox
        )
      ) {
        this.combineList.push(
          this.formData.displacement + " " + this.formData.gearbox
        );
      }
    },
    selCarChange(value, type) {
      let index = this.searchTypes.findIndex((item) => item.type == type);
      if (type == "factoryBrand") {
        this.seriesList = [];
        this.yearList = [];
        this.combineList = [];
      }
      if (type == "series") {
        this.yearList = [];
        this.combineList = [];
      }
      if (type == "year") {
        this.combineList = [];
      }
      if (index < 3) {
        for (let i = 0; i < 3; i++) {
          if (index < i) {
            this.formData[this.searchTypes[i].type] = "";
          }
        }
        this.formData.displacement = "";
        this.formData.gearbox = "";
        this.combine = "";
        this.formData[type] = value;
        this.getCarBrand(this.searchTypes[index + 1].type);
      } else {
        this.formData.displacement = value.split(" ")[0];
        this.formData.gearbox = value.split(" ")[1];
        this.combine = value;
      }
    },
    getCarBrand(type) {
      let params = {};
      if (type == "factoryBrand") {
        params.type = type;
      }
      if (type == "series") {
        params.type = type;
        params.factoryBrand = this.formData.factoryBrand;
      }
      if (type == "year") {
        params.type = type;
        params.factoryBrand = this.formData.factoryBrand;
        params.series = this.formData.series;
      }
      if (type == "combine") {
        params.type = type;
        params.factoryBrand = this.formData.factoryBrand;
        params.series = this.formData.series;
        params.year = this.formData.year;
      }
      getVehModel(params)
        .then((data) => {
          if (data && data.length > 0) {
            if (type == "factoryBrand") {
              this.factoryBrandList = data;
            }
            if (type == "series") {
              this.seriesList = data;
            }
            if (type == "year") {
              this.yearList = data;
            }
            if (type == "combine") {
              data.forEach((item) => {
                this.combineList.push(item.displacement + " " + item.gearbox);
              });
            }
          }
        })
        .catch((data) => {
          if (data && data.message) {
            this.$message.error(data.message);
          }
        });
    },
    toAddressFn() {
      this.dialogVisible = true;
    },
    getFileList(fileList, key) {
      this[key] = fileList;
    },
    selectQualityFn(value) {
      if (value) {
        this.qulityList.forEach((item) => {
          item.checked = false;
        });
      }
    },
    selQuility(item) {
      this.formData.qualityNone = false;
      setTimeout(() => {
        item.checked = !item.checked;
        this.formData.quality = [];
        this.qulityList.forEach((item) => {
          if (item.checked) {
            this.formData.quality.push(item.text);
          }
        });
      }, 200);
    },
    getAddress() {
      addressesListApi().then((res) => {
        if (res) {
          this.addresslist = res || [];
        }
      });
    },
    setDefaultAddress(id) {
      this.$confirm("确定将此地址设置为默认地址?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          setDefaultAddressApi(id).then(() => {
            this.$message({
              type: "success",
              message: "设置成功!",
            });
            this.getAddress();
          });
        })
        .catch((data) => {
          if (data && data.message) {
            this.$message.error(data.message);
          }
        });
    },

    getQulityList() {
      let params = {
        // type:"invoiceType",//发票类型
        type: "quality", //品质
      };
      getDict(params)
        .then((data) => {
          this.qulityList = [];
          if (data && data.length > 0) {
            data.forEach((item) => {
              this.qulityList.push({
                code: item.code,
                text: item.name,
                checked: false,
              });
            });
          }
        })
        .catch((data) => {
          if (data && data.message) {
            this.$message.error(data.message);
          }
        });
    },
    getInsureCertType() {
      let params = {
        type: "invoiceType", //发票类型
      };
      getDict(params)
        .then((data) => {
          this.invoiceTypeList = [];
          if (data && data.length > 0) {
            data.forEach((item) => {
              this.invoiceTypeList.push({
                code: item.code,
                name: item.name,
              });
            });
            this.invoiceTypeList.unshift({
              code: "none",
              name: "无限制",
            });
            console.log("invoiceTypeList", this.invoiceTypeList);
          }
        })
        .catch((data) => {
          if (data && data.message) {
            this.$message.error(data.message);
          }
        });
    },
    load(enquiryCode) {
      let params = {
        enquiryCode,
      };
      enquiryDetail(params)
        .then((res) => {
          this.formData = { ...res };
          this.formData.partDetails =
            res.details.map((item) => {
              return {
                partName: item.partName,
                qty: item.qty,
                quality: item.quality,
              };
            }) || [];
          if (res.quality?.length) {
            this.formData.qualityNone = false;
          } else {
            this.formData.qualityNone = true;
          }
          this.invoiceType = res.invoiceType != "" ? res.invoiceType : "无限制";
          this.combine =
            this.formData.displacement + " " + this.formData.gearbox;
          let pictures = res.pictures || [];
          if (pictures && pictures.length > 0) {
            this.pictures = [];
            this.pictures = pictures.map((item) => {
              return {
                uid: item,
                url: item,
                origin: item,
              };
            });
          }
          if (this.formData.factoryBrand) {
            this.getCarBrand("series");
          }
          if (this.formData.series) {
            this.getCarBrand("year");
          }
          if (this.formData.year) {
            this.getCarBrand("combine");
          }
          this.$forceUpdate();
        })
        .catch((data) => {
          if (data && data.message) {
            this.$message.error(data.message);
          }
        });
    },
    Upload() {
      //上传EXcel
      let file = this.$refs.excelFile.files[0];
      if (file && file.name.indexOf(".xlsx") < 0) {
        this.$message.error("文件类型不正确！请上传xlsx文件。");
        return false;
      }
      if (file) {
        this.Uploadshow = this.$loading({
          lock: true,
          text: "excel导入中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        this.readWorkbookFromLocalFile(file);
      }
    },
    readWorkbookFromLocalFile(file) {
      let that = this;
      let reader = new FileReader();
      reader.onload = function(e) {
        let data = e.target.result;
        let workbook = read(data, { type: "binary" });
        let excelJson = utils.sheet_to_json(workbook.Sheets.Sheet1);
        console.log("excelJson", excelJson);
        let keyObj = {
          // "* 配件编码": "partCode",
          配件名称: "partName",
          数量: "qty",
          品质: "quality",
        };
        let excelData = JSON.parse(JSON.stringify(excelJson));
        excelData.forEach((item) => {
          // 将中文的键名替换成英文的
          for (let k in keyObj) {
            let newKey = keyObj[k];
            if (newKey) {
              item[newKey] = item[k];
              delete item[k];
            }
          }
        });
        let newExcel = excelData.map((item) => {
          return {
            partName: item.partName,
            qty: item.qty,
            quality: item.quality,
          };
        });
        that.formData.partDetails = that.formData.partDetails.concat([
          ...newExcel,
        ]);
        that.Uploadshow.close();
        that.$refs.excelFile.value = "";
        that.$forceUpdate();
        // console.log("newExcel", newExcel);
        // that.readExcelJson(excelJson);
      };
      reader.readAsBinaryString(file);
    },
    // readExcelJson(excelJson) {
    //   try {
    //     if (excelJson && excelJson.length > 0) {
    //       this.formData.partDetails.forEach((item, index) => {
    //         if (item.partCode == "") {
    //           this.formData.partDetails.splice(index, 1);
    //         }
    //       });
    //       excelJson.forEach((item, index) => {
    //         let part = {};
    //         for (var prop in item) {
    //           if (!item.hasOwnProperty("* 配件编码")) {
    //             throw new Error(
    //               `第${index + 1}行数据错误，配件编码必填！请修改后重新上传`
    //             );
    //           }
    //           if (!item.hasOwnProperty("* 数量")) {
    //             throw new Error(
    //               `第${index + 1}行数据错误，数量必填！请修改后重新上传`
    //             );
    //           }
    //           if (this.sheet.includes(prop)) {
    //             this.sheetClomns.forEach((clomn) => {
    //               if (
    //                 prop == clomn.key &&
    //                 prop == "* 数量" &&
    //                 !/^[0-9]*[1-9][0-9]*$/.test(item[prop])
    //               ) {
    //                 throw new Error(
    //                   `第${index +
    //                     1}行数据错误，数量只能为大于1的整数！请修改后重新上传`
    //                 );
    //               } else if (prop == clomn.key) {
    //                 part[clomn.value] = item[prop].toString();
    //               }
    //             });
    //           } else {
    //             this.Uploadshow = false;
    //             this.$alert("模板不正确！请下载模板后重新上传", "提示", {
    //               confirmButtonText: "确定",
    //               callback: () => {},
    //             });
    //             return false;
    //           }
    //         }
    //         if (!part.hasOwnProperty("partName")) {
    //           part.partName = "";
    //         }
    //         if (!part.hasOwnProperty("quality")) {
    //           part.quality = "";
    //         }
    //         this.formData.partDetails.push(part);
    //       });
    //       this.Uploadshow = false;
    //     } else {
    //       this.Uploadshow = false;
    //       this.$alert("没有数据！请重新上传", "提示", {
    //         confirmButtonText: "确定",
    //         callback: () => {},
    //       });
    //       return false;
    //     }
    //   } catch (e) {
    //     this.Uploadshow = false;
    //     this.$alert(e, "提示", {
    //       confirmButtonText: "确定",
    //       callback: () => {},
    //     });
    //   }
    //   $("#uploadTemplate").val("");
    // },
  },
};
</script>

<style lang="less" scoped>
.postEnquiry {
  height: calc(100vh - 224px);
  .absolute {
    top: 10px;
    right: 0;
    font-size: 14px;
  }
  .vin-code {
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url("~@/assets/img/icons/photo.png") no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
  }
  .btn_hover:hover {
    color: #ff6e4c;
  }
  .item_else_box {
    margin: 12px 12px 12px 0;
    background: #ffffff;
    border: 1px solid #ededed;
    border-radius: 4px;
    padding: 12px;
    &:last-child {
      margin: 12px 0;
    }
  }
  .item_title {
    background: #ffeeeb;
    border-radius: 0px 15px 15px 0px;
    padding: 5px 12px;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #ff6e4c;
    line-height: 22px;
    margin-left: -12px;
  }
  .pin_radio {
    .el-radio {
      margin: 0 12px 0 0;
    }
  }
  .address_item {
    .el-radio {
      margin: 0;
    }
    /deep/.el-radio__input {
      margin-right: 12px;
    }
    /deep/.el-radio__label {
      display: none;
    }
  }
  .item_quality {
    background: #f4f4f4;
    border-radius: 16px;
    padding: 4px 8px;
    font-size: 12px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #666666;
    line-height: 16px;
    margin: 4px 12px 4px 0;
    cursor: pointer;
    &:first-child {
      margin: 4px 12px;
    }
    &.active {
      background: #ff6e4c;
      color: #ffffff;
    }
  }
  .upload_img {
    /deep/.el-upload--picture-card,
    /deep/.el-upload-list--picture-card .el-upload-list__item {
      width: 64px;
      height: 64px;
    }
  }
  .el-button--small.is-round {
    padding: 5px 16px;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    line-height: 22px;
  }
  .el-button--default:hover {
    border-color: #ff6e4c;
    color: #ff6e4c;
    background-color: #ff6e4c24;
  }
  /deep/.el-tabs__item.is-active,
  /deep/.el-tabs__item:hover {
    color: #ff6e4c;
  }
  /deep/.el-tabs__active-bar {
    background-color: #ff6e4c;
  }
  /deep/.el-radio__input.is-checked + .el-radio__label {
    color: #ff6e4c;
  }
  /deep/.el-radio__input.is-checked .el-radio__inner {
    border-color: #ff6e4c;
    background: #ff6e4c;
  }
  /deep/.el-radio {
    margin-top: 8px;
  }
}
</style>
