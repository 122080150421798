import { render, staticRenderFns } from "./partsTableList.vue?vue&type=template&id=289c2515&scoped=true&"
import script from "./partsTableList.vue?vue&type=script&lang=js&"
export * from "./partsTableList.vue?vue&type=script&lang=js&"
import style0 from "./partsTableList.vue?vue&type=style&index=0&id=289c2515&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "289c2515",
  null
  
)

export default component.exports